<template>  
      <div class="container-fluid main-wrapper clearfix">
            <!-- =================================== -->
            <!-- Different data widgets ============ -->
            <!-- =================================== -->
            <div class="widget-list row">
                <div id="cta-page__default" class="widget-holder col-md-12 p-0">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                                <div class="col-md-8 offset-md-2">
                                    <div class="element-cta element-cta-1" id="cta-page__cta" style="background: none;" v-if="resource === 'burnout'">
                                        <h6 class="section-sub-heading-lines text-center"><i class="vibes-vibes"></i> <span class="title">How To Get Rid Of Habitual Burnout</span></h6>
                                        <!-- /.section-sub-heading -->
                                        <video autoplay controls style="max-width: 100%;">
                                          <source src="https://storage.googleapis.com/cherise/burnout-final.mp4" type="video/mp4">
                                          Your browser does not support the video tag.
                                        </video>
                                        <h6 class="section-sub-heading-lines text-center"><i class="vibes-vibes"></i> <span class="title">Schedule a Roadmap Session</span></h6>
                                       <vue-calendly url="https://calendly.com/hannah-bacaron/roadmap?hide_event_type_details=1" :height="720"></vue-calendly>
                                    </div>
                                    <div class="element-cta element-cta-1" id="cta-page__cta" style="background: none;" v-else>
                                        <h6 class="section-sub-heading-lines text-center"><i class="vibes-vibes"></i> <span class="title">How To Get Rid Of Habitual Burnout</span></h6>
                                        <!-- /.section-sub-heading -->
                                        <img src="/assets/img/burnout-teaser.png" style="max-width: 100%;" />
                                    </div>
                                </div>
                                <!-- /.col-md-12 -->
                            </div>
                            <!-- /.row -->
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
            </div>
			<template v-if="!resource">
            <!-- /.widget-list -->
           <div style="width: 100%; height: 100vh; position: fixed; background: #000; top: 0; left:0; bottom: 0; right: 0; opacity: .6;">
           </div>
           <div style="opacity: 1; position: fixed; top: 200px; left: 0; right: 0;  z-index:999999999; text-align: center;" class="container">
             <div class="row">
               <div class="col-md-6 offset-md-3 pd-t-20" style="background: #fff; margin: 0 auto; position; absolute; border-radius: 10px;">
				<h4 style="font-family: 'Poppins', sans-serif; font-weight: 400; line-height: 30px;">Learn how to get rid of habitual burnout,<br/>meet your psychological needs everyday,<br />and achieve more in life with this training.</h4>
                 <h5 style="font-family: 'Poppins', sans-serif; font-weight: 300;">
					Fill out your email below to access this exclusive content
				</h5>
                 <form style="margin-top: 30px;" @submit.prevent="submit(first_name, last_name, email)">
					<div class="row">
						<div class="col">
							<div class="form-group mb-1">
								<div class="input-group">
									<input class="form-control form-control-rounded" placeholder="First Name" type="text" v-model="first_name" required>
									<input class="form-control form-control-rounded" placeholder="Last Name" type="text" v-model="last_name" required>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<div class="form-input-icon"><i class="feather-mail"></i>
							<input class="form-control form-control-rounded" placeholder="Email Address" type="email" v-model="email" required>
						</div>
					</div>
					<div class="form-group">
						<button type="submit" class="btn btn-rounded btn-primary btn-primary-gradient small">Watch The Video</button>
					</div>
				</form>
				<div v-if="showError" class="alert alert-danger">There was an error collecting your information. Please make sure it is complete and valid, then try again...</div>
               </div>
             </div>
             <div class="row">
               <div class="col-md-6 offset-md-3 pd-t-20" style="margin: 0 auto; position; absolute; border-radius: 10px;">
               </div>
             </div>
           </div>
			</template>
    </div>
</template>

<script>
import axios from 'axios';
import AOS from 'aos';
export default {
  name: 'Share',
  data () {
    return {
      email: null,
      first_name: null,
      last_name: null,
      showError: false
    }
  },
  computed: {
    resource () {
      return this.$route.params.resource;
    }
  },
  methods: {
    /* eslint-disable */
    async submit (first_name, last_name, email) {
      this.showError = false;
      if (first_name && last_name && email) {
        this.result = await axios.post('/.netlify/functions/carrier-crow-subscribe', { first_name, last_name, email }).then(res => res.data.result);
        this.$router.push('/share/burnout');
      } else {
        this.showError = true;
      }
    }
  },
  mounted () {
    AOS.refresh();
    !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/3cbe89e033208c16500b72cb9/2bcf897e14730b263412e5007.js");
  }
}
</script>
