<template>
        <div class="container-fluid main-wrapper clearfix">
            <!-- =================================== -->
            <!-- Different data widgets ============ -->
            <!-- =================================== -->
            <div class="widget-list row">
                <div id="top"></div>
                <!-- Slideshow -->
                <div class="widget-holder col-md-12 p-0">
                    <div class="widget-bg">
                        <div class="widget-body container-fluid">
                            <div class="row slick-container slick-container-home" id="slider-home-main">
                                <div class="slick-slider" data-slick='{"fade": true, "dots": false}'>
                                    <!-- /.slider-item -->
                                    <div class="slider-item slide-1" data-set-header-skin="light">
                                        <div class="col-md-6 mx-auto text-center">
                                            <h3 class="h3 fw-500 text-white lh-10 mr-b-50" id="slide-1-title" data-animation-in="flipInX" style="font-size: 75px;">Awaken your full potential</h3>
                                            <p style="font-size: 25px; line-height: 35px; text-shadow: 0px 0px 20px #000; color: #fff;">Learn how to feel recharged every day to stop habitual burnout</p>
                                            <router-link href="javascript:void(0)" class="btn btn-fancy mr-lr-15" data-animation-in="fadeInLeft" data-animation-in-delay="400ms" to="/share"><i class="feather-play fs-18 mr-3 pl-1 align-middle"></i> Play Video Now</router-link>
                                            <!--<a href="javascript:void(0)" class="btn btn-line-under mr-lr-15 text-white" data-animation-in="fadeInRight" data-animation-in-delay="400ms">Explore Details</a>-->
                                        </div>
                                        <!-- /.col-md-4 -->
                                        <img src="assets/demo/home/swirl-1.png" id="slide-1-swirl" alt="bg" class="animated-bg-swirl">
                                    </div>
                                    <!-- /.slider-item -->
                                </div>
                                <!-- /.slick-slider -->
                            </div>
                            <!-- /.slick-container -->
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <div id="home__features-widget" class="widget-holder col-md-12 p-0">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="section-sub-heading-lines text-left"><i class="vibes-vibes"></i> <span class="title">Benefits of an Evolved Mind</span></h6>
                                    <!-- /.section-sub-heading -->
                                    <h2 class="fancy-heading mr-b-70">Regain Control
                                    <br /><span style="font-size: 35px;">Transform your mind = Transform your life</span></h2>
                                    <!-- /.fancy-heading -->
                                    <div class="row" id="icon-box-list">
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-duration="800">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-yoga-1 text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Overcome Self Sabotage & Burnout</h6>
                                                    <p>Gain self worth, develop leadership skills, remove self doubt, gain self confidence, improve concentration/focus, trust your decisions, and overcome procrastination.</p>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-delay="100" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <figure class=""><i class="vibes-strong text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Eliminate Fears and Anxieties</h6>
                                                    <p>Get rid of any fear holding you back from achieving your goal: fear of public speaking, fear of getting on videos streams, improve negotiation, imposter syndrome, confrontation, and more.</p>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-healthy text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Improve Overall Health</h6>
                                                    <p>Reverse aging, reverse stress-related ailments, gain more energy, improve quality of sleep, release muscle tensions, and relieve headaches.</p>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-branch text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Create Healthy Relationships</h6>
                                                    <p>Improve strained relationships, drastically improve communication skills, break bad relationship habits, and build a better relationship with yourself.</p>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                    </div>
                                    <!-- /.row -->
                                </div>
                                <!-- /.col-md-12 -->
                            </div>
                            <!-- /.row -->
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
                <!-- Quotes Carousel -->
                <div id="quotes-page__carousel-widget" class="widget-holder col-md-12 p-0">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="fancy-heading mr-b-30 text-center" data-aos="zoom-in" data-aos-duration="800"><span>Testimonials</span></h2>
                                    <div class="quotes-carousel-container slick-container" data-aos="fade-left" data-aos-duration="800">
                                        <div class="slick-slider quotes-carousel" data-slick='{
                                            "arrows": false,
                                            "autoplay": false,
                                          }' data-nav-target="#carousel-quotes-1-nav" id="carousel-quotes-1">
                                            <!-- /.slider-item -->
                                            <blockquote class="slider-item">
                                                <p class="mb-0">"It really was a very quick change, which really surprised me, to be honest...
                                                It was like nothing I've ever experienced before."</p>
                                                <br /><br />
                                                <p class="mb-0">Listen to the full audio testimonal below:</p>
                                                <br />
                                                <audio controls>
                                                  <source src="/testimonial/audio/ben-turnbul.m4a" type="audio/mp4">
                                                  <p class="alert alert-warning">Your browser does not support HTML5 audio. <a href="/testimonial/audio/ben-turnbul.m4a">Download the file</a></p>
                                                </audio>
                                            </blockquote>
                                            <!-- /.slider-item -->
                                            <blockquote class="slider-item">
                                                <p class="mb-0">"I've been going hiking every Saturday with my friends.
                                                This is going to be the fifth Monday in a row I go to yoga.
                                                Me and my Husband have been going to the gym a couple times a week...
                                                My depression has been gone...I haven't had any depression."</p>
                                                <br /><br />
                                                <p class="mb-0">Listen to the full audio testimonal below:</p>
                                                <br />
                                                <audio controls>
                                                  <source src="/testimonial/audio/tatiana-flosi.m4a" type="audio/mp4">
                                                  <p class="alert alert-warning">Your browser does not support HTML5 audio. <a href="/testimonial/audio/tatiana-flosi.m4a">Download the file</a></p>
                                                </audio>
                                            </blockquote>
                                            <!-- /.slider-item -->
                                            <blockquote class="slider-item">
                                                <p class="mb-0">After 3 months...</p>
                                                <br /><br />
                                                <p class="mb-0">Listen to the full audio testimonal below:</p>
                                                <br />
                                                <audio controls>
                                                  <source src="/testimonial/audio/antoinette-bantigue.mp3" type="audio/mp3">
                                                  <p class="alert alert-warning">Your browser does not support HTML5 audio. <a href="/testimonial/audio/antoinette-bantigue.mp3">Download the file</a></p>
                                                </audio>
                                            </blockquote>
                                            <!-- /.slider-item -->
                                            <blockquote class="slider-item">
                                                <p class="mb-0">After 2 months...</p>
                                                <br /><br />
                                                <p class="mb-0">Listen to the full audio testimonal below:</p>
                                                <br />
                                                <audio controls>
                                                  <source src="/testimonial/audio/ariana-doakas.m4a" type="audio/mp4">
                                                  <p class="alert alert-warning">Your browser does not support HTML5 audio. <a href="/testimonial/audio/ariana-doakas.mp4">Download the file</a></p>
                                                </audio>
                                            </blockquote>
                                            <!-- /.slider-item -->
                                            <blockquote class="slider-item">
                                                <p class="mb-0">I felt empowered, really. I'm doing the work and I know what I need to do. I'm not my emotions. I got that sense of clarity again and that momentum I need to show up physically</p>
                                                <br /><br />
                                                <p class="mb-0">Listen to the full audio testimonal below:</p>
                                                <br />
                                                <audio controls>
                                                  <source src="/testimonial/audio/mario-dominguez.m4a" type="audio/mp4">
                                                  <p class="alert alert-warning">Your browser does not support HTML5 audio. <a href="/testimonial/audio/ariana-doakas.mp4">Download the file</a></p>
                                                </audio>
                                            </blockquote>
                                            <!-- /.slider-item -->
                                            <blockquote class="slider-item">
                                                <p class="mb-0">8 Sessions</p>
                                                <br /><br />
                                                <p class="mb-0">Listen to the full audio testimonal below:</p>
                                                <br />
                                                <audio controls>
                                                  <source src="/testimonial/audio/sam-clitheroe.m4a" type="audio/mp4">
                                                  <p class="alert alert-warning">Your browser does not support HTML5 audio. <a href="/testimonial/audio/ariana-doakas.mp4">Download the file</a></p>
                                                </audio>
                                            </blockquote>
                                        </div>
                                        <!-- /.quotes-carousel -->
                                    </div>
                                    <!-- /.quotes-carousel-container -->
                                    <!--Thumbnail slider container-->
                                    <div class="quotes-carousel-thumbs-container slick-container" data-aos="fade-right" data-aos-duration="800">
                                        <div class="slick-slider-nav quotes-carousel-thumbs" id="carousel-quotes-1-nav" data-nav-target="#carousel-quotes-1" data-slick='{
                                               "centerMode": true,
                                               "centerPadding": 0,
                                               "slidesToShow": 1,
                                               "slidesToScroll": 5,
                                               "arrows": false,
                                               "responsive": [
                                                {
                                                  "breakpoint": 1200,
                                                  "settings": {
                                                    "slidesToShow": 5,
                                                    "slidesToScroll": 5,
                                                    "centerMode": true
                                                  }
                                                }, {
                                                  "breakpoint": 768,
                                                  "settings": {
                                                    "slidesToShow": 3,
                                                    "slidesToScroll": 3,
                                                    "centerMode": true
                                                  }
                                                }, {
                                                  "breakpoint": 576,
                                                  "settings": {
                                                    "slidesToShow": 1,
                                                    "slidesToScroll": 1,
                                                    "centerMode": true
                                                  }
                                                }
                                               ]
                                             }'>
                                            <!-- /.item -->
                                            <div class="slider-item">
                                                <figure>
                                                    <img src="/testimonial/image/ben-turnbul.jpg" alt="Ben Turnbul Headshot">
                                                </figure>
                                                <div>
                                                    <p class="headings-color m-0">Ben Turnbul</p><small>Full-Time Employee<br />Business Owner</small>
                                                </div>
                                            </div>
                                            <!-- /.item -->
                                            <div class="slider-item">
                                                <figure>
                                                    <img src="/testimonial/image/tatiana-flosi.png" alt="Tatiana Flosi Headshot">
                                                </figure>
                                                <div>
                                                    <p class="headings-color m-0">Tatiana Flosi</p><small>Mother of Two</small>
                                                </div>
                                            </div>
                                            <!-- /.item -->
                                            <div class="slider-item">
                                                <figure>
                                                    <img src="/testimonial/image/antoinette-bantigue.png" alt="Antoinette Bantigue Headshot">
                                                </figure>
                                                <div>
                                                    <p class="headings-color m-0">Antoinette<br />Bantigue</p><small>Registered Nurse</small>
                                                </div>
                                            </div>
                                            <!-- /.item -->
                                            <div class="slider-item">
                                                <figure>
                                                    <img src="/testimonial/image/ariana-doakas.jpg" alt="Ariana Doakas Headshot">
                                                </figure>
                                                <div>
                                                    <p class="headings-color m-0">Ariana Doakas</p><small>Director of Finance at Super Connector Media</small>
                                                </div>
                                            </div>
                                            <!-- /.item -->
                                            <div class="slider-item">
                                                <figure>
                                                    <img src="/testimonial/image/mario-dominguez.jpg" alt="Mario Dominguez">
                                                </figure>
                                                <div>
                                                    <p class="headings-color m-0">Mario Dominguez</p><small>Owner & Head Coach at Fit Mind Fit Body</small>
                                                </div>
                                            </div>
                                            <!-- /.item -->
                                            <div class="slider-item">
                                                <figure>
                                                    <img src="/testimonial/image/sam-clitheroe.jpg" alt="Sam Clitheroe">
                                                </figure>
                                                <div>
                                                    <p class="headings-color m-0">Sam Clitheroe</p><small>Director of Marketing at Gerald Adams</small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- /.quotes-carousel-thumbs -->
                                        <div class="slick-slider-controls"><a class="slick-slider-left" href="javascript:;"><span><i class="feather-arrow-left"></i></span> </a><a class="slick-slider-right" href="javascript:;"><span><i class="feather-arrow-right"></i></span></a>
                                        </div>
                                        <!-- /.slick-slider-controls -->
                                    </div>
                                    <!-- /.quotes-carousel-thumbs-container -->
                                </div>
                                <!-- /.col-md-12 -->
                            </div>
                            <!-- /.row -->
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
            </div>
            <!-- /.widget-list -->
            <div id="loom-modal" class="modal animated fadeInDown" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                    </button> 
                    <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/f8b5425005534446a0b677e9e2073276" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
                  </div>
                </div>
              </div>
            </div>
      </div>
</template>

<script>
import AOS from 'aos';
export default {
  name: 'Home',
  data () {
    return {
      publishableKey: 'pk_live_rIGlZkA2GerkkSlgokChv68P',
      items: {
        course: [{price: 'price_HM0WrP8sfGAcqD', quantity: 1}]
      },
      successUrl: 'https://yourstressremedy.com/course/success',
      cancelUrl: 'https://yourstressremedy.com/course/cancel'
    }
  },
  methods: {
    checkout (refKey) {
      this.$refs[refKey].redirectToCheckout();
    }
  },
  mounted () {
    AOS.refresh();
    document.dispatchEvent(new Event('DOMContentLoaded'));
  }
}
</script>

<style lang="scss">
  .calendly-outter {
    border: solid 5px #505960;
    box-shadow: 2px 2px 10px #000;
  } 
  /*#carousel-quotes-1-nav {
    .slick-track {
      width: auto !important;
    }
  }*/
</style>
