<template> 
      <div class="container-fluid main-wrapper clearfix">
            <!-- =================================== -->
            <!-- Different data widgets ============ -->
            <!-- =================================== -->
            <div class="widget-list row">
                <div class="col-md-12 p-0 d-none d-sm-block">
                    <div class="text-center">
                        <div class="widget-body container" style="padding-top: 100px;">
                            <img src="/assets/img/personality-bg.png" style="width: 900px; max-width: 100%;" />
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
                <div id="about-page__info-widget" class="widget-holder col-md-12 p-0" v-if="!resultPage">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                                <div class="col-md-5">
                                    <h4 class="font-family-base fw-500" id="about-page__info-title"><small>step 1</small><br />Discovering You</h4>
                                </div>
                                <!-- /.col-md-5 -->
                                <div class="col-md-7">
                                    <p>This is a short, fun personality test to help us get a baseline understanding of you. This is a forced-choice test which requires you to choose one statemtn in each pair of statements that describes you best. For each pair of statements select the statement that applies to you <b class="bam"><em>MOST OF YOUR LIFE</em></b>. Do not over-analyze the questions, or try to think of "exceptions to the rule". Be spontaneous and choose the statement that comes closest to the way you have been most of the time, most of your life.</p>
                                    <p>Occasionally there will be questions that ask you to make a close call between two choices. This is because it is trying to reveal areas where you have similar behaviors or attitudes. If a question is very close and you can’t decide which statement applies to you best, you can come back to it after you have completed.</p>
                                    <p>This <b class="bam"><em>usually takes about 10 minutes to complete</em></b>. At the end, click on the Score Test button, and wait for the computer to generate a personality profile of your scores.</p>
                                    <p>Thanks!</p>
                                    <p><em>We cannot guarantee the accuracy of the results of the personality analysis produced by this short personality quiz — only that it can help you begin your journey of self-discovery!</em></p>
                                </div>
                                <!-- /.col-md-7 -->
                            </div>
                            <!-- /.row -->
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
                <div id="about-page__gallery-widget" class="widget-holder col-md-12 p-0">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                                <div class="col-md-10 mx-auto pos-relative test-form" id="about-page__accordion" v-if="!result">
                                    <h3 class="pb-5">Answer all the questions below</h3>
                                    <ol>
                                        <li>
                                            I've Been:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q1"  class="form-check-input" type="radio" value="a" name="q1">
                                                    <label class="form-check-label">
                                                        Romantic and Imaginative
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q1"  class="form-check-input" type="radio" value="b" name="q1">
                                                    <label class="form-check-label">
                                                        Pragmatic and Down To Earth
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've tended to:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q2"  class="form-check-input" type="radio" value="a" name="q2">
                                                    <label class="form-check-label">
                                                        Take on confrontations
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q2"  class="form-check-input" type="radio" value="b" name="q2">
                                                    <label class="form-check-label">
                                                        Avoid confrontations
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have typtically been:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q3"  class="form-check-input" type="radio" value="a" name="q3">
                                                    <label class="form-check-label">
                                                        Diplomatic, charming and ambitious
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q3"  class="form-check-input" type="radio" value="b" name="q3">
                                                    <label class="form-check-label">
                                                        Direct, formal and idealistic
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have tended to be:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q4"  class="form-check-input" type="radio" value="a" name="q4">
                                                    <label class="form-check-label">
                                                        Focused and intense
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q4"  class="form-check-input" type="radio" value="b" name="q4">
                                                    <label class="form-check-label">
                                                        Spontaneous and fun loving
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have been a:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q5"  class="form-check-input" type="radio" value="a" name="q5">
                                                    <label class="form-check-label">
                                                        Hospitable person and have enjoyed welcoming new friends into my life
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q5"  class="form-check-input" type="radio" value="b" name="q5">
                                                    <label class="form-check-label">
                                                        Private person and have not mixed much with others
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Generally, it's been:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q6"  class="form-check-input" type="radio" value="a" name="q6">
                                                    <label class="form-check-label">
                                                        Easy to "get a rise" out of me
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q6"  class="form-check-input" type="radio" value="b" name="q6">
                                                    <label class="form-check-label">
                                                        Difficult to "get a rise" out of me
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've been more of a:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q7"  class="form-check-input" type="radio" value="a" name="q7">
                                                    <label class="form-check-label">
                                                        "Street smart" survivor
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q7"  class="form-check-input" type="radio" value="b" name="q8">
                                                    <label class="form-check-label">
                                                        "High-minded" idealist
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q8"  class="form-check-input" type="radio" value="a" name="q9">
                                                    <label class="form-check-label">
                                                        Needed to show affection to poeple
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q8"  class="form-check-input" type="radio" value="b" name="q9">
                                                    <label class="form-check-label">
                                                        Preferred to maintain a certain distance with people
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            When presented with a new experience, I've usually asked myself if it would be:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q9"  class="form-check-input" type="radio" value="a" name="q10">
                                                    <label class="form-check-label">
                                                        Useful to me
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q9"  class="form-check-input" type="radio" value="b" name="q10">
                                                    <label class="form-check-label">
                                                        Enjoyable
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have tended to focus too much on:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q10"  class="form-check-input" type="radio" value="a" name="q11">
                                                    <label class="form-check-label">
                                                        Myself
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q10"  class="form-check-input" type="radio" value="b" name="q11">
                                                    <label class="form-check-label">
                                                        Others
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Others have depended on my:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q11"  class="form-check-input" type="radio" value="a" name="q12">
                                                    <label class="form-check-label">
                                                        Insight and knowledge
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q11"  class="form-check-input" type="radio" value="b" name="q12">
                                                    <label class="form-check-label">
                                                        Strength and decisiveness
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have come across as being too:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q12"  class="form-check-input" type="radio" value="a" name="q13">
                                                    <label class="form-check-label">
                                                        Unsure of myself
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q12"  class="form-check-input" type="radio" value="b" name="q13">
                                                    <label class="form-check-label">
                                                        Sure of myself
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have been more:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q13"  class="form-check-input" type="radio" value="a" name="q14">
                                                    <label class="form-check-label">
                                                        Relationship oriented than goal oriented
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q13"  class="form-check-input" type="radio" value="b" name="q14">
                                                    <label class="form-check-label">
                                                        Goal oriented than relationship oriented
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q14"  class="form-check-input" type="radio" value="a" name="q15">
                                                    <label class="form-check-label">
                                                        Not been able to speak for myself very well
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q14"  class="form-check-input" type="radio" value="b" name="q15">
                                                    <label class="form-check-label">
                                                        Been outspoken &ndash; I've said what others wish they had the nerve to say
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            It's been difficult for me to:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q15"  class="form-check-input" type="radio" value="a" name="q16">
                                                    <label class="form-check-label">
                                                        Stop considering alternatives and do something definite
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q15"  class="form-check-input" type="radio" value="b" name="q16">
                                                    <label class="form-check-label">
                                                        Take it easy and be more flexible
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have tended to be:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q16"  class="form-check-input" type="radio" value="a" name="q17">
                                                    <label class="form-check-label">
                                                        Hesitant and procrastinating
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q16"  class="form-check-input" type="radio" value="b" name="q17">
                                                    <label class="form-check-label">
                                                        Bold and domineering
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            My:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q17"  class="form-check-input" type="radio" value="a" name="q18">
                                                    <label class="form-check-label">
                                                        Reluctance to get too involved has gotten my into trouble with people
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q17"  class="form-check-input" type="radio" value="b" name="q18">
                                                    <label class="form-check-label">
                                                        Eagerness to have people depend on me has gotten me into trouble with them
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Usually, I have:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q18"  class="form-check-input" type="radio" value="a" name="q19">
                                                    <label class="form-check-label">
                                                        Been able to put my feelings aside to get the job done
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q18"  class="form-check-input" type="radio" value="b" name="q19">
                                                    <label class="form-check-label">
                                                        Needed to work through my feelings before I could act
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Generally, I have been:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q19"  class="form-check-input" type="radio" value="a" name="q20">
                                                    <label class="form-check-label">
                                                        Methodical and cautious
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q19"  class="form-check-input" type="radio" value="b" name="q20">
                                                    <label class="form-check-label">
                                                        Adventurouse and taken risks
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have tended to be a:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q20"  class="form-check-input" type="radio" value="a" name="q21">
                                                    <label class="form-check-label">
                                                        Supportive, giving person who enjoys the company of others
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q20"  class="form-check-input" type="radio" value="b" name="q21">
                                                    <label class="form-check-label">
                                                        Serious, reserved person who likes discussing issues
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've often felt the need to:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q21"  class="form-check-input" type="radio" value="a" name="q22">
                                                    <label class="form-check-label">
                                                        Be a "pillar of strength"
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q21"  class="form-check-input" type="radio" value="b" name="q22">
                                                    <label class="form-check-label">
                                                        Perform perfectly
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've typically been interested in:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q22"  class="form-check-input" type="radio" value="a" name="q23">
                                                    <label class="form-check-label">
                                                        Asking tough questions and maintaining my independance
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q22"  class="form-check-input" type="radio" value="b" name="q23">
                                                    <label class="form-check-label">
                                                        Maintaining my stability and peace of mind
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've been too:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q23"  class="form-check-input" type="radio" value="a" name="q24">
                                                    <label class="form-check-label">
                                                        Hard nosed and skeptical
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q23"  class="form-check-input" type="radio" value="b" name="q24">
                                                    <label class="form-check-label">
                                                        Soft-hearted and sentimental
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've often worried that:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q24"  class="form-check-input" type="radio" value="a" name="q25">
                                                    <label class="form-check-label">
                                                        I'm going to miss out on something better
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q24"  class="form-check-input" type="radio" value="b" name="q25">
                                                    <label class="form-check-label">
                                                        If I let my guard down, someone will take advantage of me
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            My habit of:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q25"  class="form-check-input" type="radio" value="a" name="q26">
                                                    <label class="form-check-label">
                                                        Being "stand-offish" has annoyed people
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q25"  class="form-check-input" type="radio" value="b" name="q26">
                                                    <label class="form-check-label">
                                                        Telling people what to do has annoyed people
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Usually, when troubles have gotten to me, I have:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q26"  class="form-check-input" type="radio" value="a" name="q27">
                                                    <label class="form-check-label">
                                                        Been able to "tune them out"
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q26"  class="form-check-input" type="radio" value="b" name="q27">
                                                    <label class="form-check-label">
                                                        Treated myself to something I've enjoyed
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q27"  class="form-check-input" type="radio" value="a" name="q28">
                                                    <label class="form-check-label">
                                                        Depended upon my friends and they have known they can depend on me
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q27"  class="form-check-input" type="radio" value="b" name="q28">
                                                    <label class="form-check-label">
                                                        Not depended on people; I have done things on my own
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have tended to be:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q28"  class="form-check-input" type="radio" value="a" name="q29">
                                                    <label class="form-check-label">
                                                        Detached and preoccupied
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q28"  class="form-check-input" type="radio" value="b" name="q29">
                                                    <label class="form-check-label">
                                                        Moody and self-absorbed
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have liked to:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q29"  class="form-check-input" type="radio" value="a" name="q30">
                                                    <label class="form-check-label">
                                                        Challenge people and "shake them up"
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q29"  class="form-check-input" type="radio" value="b" name="q30">
                                                    <label class="form-check-label">
                                                        Comfort people and calm them down
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have generally been an:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q30"  class="form-check-input" type="radio" value="a" name="q31">
                                                    <label class="form-check-label">
                                                        Outgoing, sociable person
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q30"  class="form-check-input" type="radio" value="b" name="q31">
                                                    <label class="form-check-label">
                                                        Earnest, self-disciplined person
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've usually:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q31"  class="form-check-input" type="radio" value="a" name="q32">
                                                    <label class="form-check-label">
                                                        Been shy about showing my abilities
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q31"  class="form-check-input" type="radio" value="b" name="q32">
                                                    <label class="form-check-label">
                                                        Liked to let people know what I can do well
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Which best describes you?
                                            <ul>
                                                <li>
                                                    <input v-model="form.q32"  class="form-check-input" type="radio" value="a" name="q33">
                                                    <label class="form-check-label">
                                                        Persuing my personal interests has been more important to me than having comfort and security
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q32"  class="form-check-input" type="radio" value="b" name="q33">
                                                    <label class="form-check-label">
                                                        Having comfort and security has been more important to me than pursuing my personal interestes
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            When I've had conflict with others, I've:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q33"  class="form-check-input" type="radio" value="a" name="q34">
                                                    <label class="form-check-label">
                                                        Tended to withdraw
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q33"  class="form-check-input" type="radio" value="b" name="q34">
                                                    <label class="form-check-label">
                                                        Rarely backed down
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I have:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q34"  class="form-check-input" type="radio" value="a" name="q35">
                                                    <label class="form-check-label">
                                                        Given in too easily and let others push me around
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q34"  class="form-check-input" type="radio" value="b" name="q35">
                                                    <label class="form-check-label">
                                                        Been too uncompromising and demanding with others
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            I've appreciated for my:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q35"  class="form-check-input" type="radio" value="a" name="q36">
                                                    <label class="form-check-label">
                                                        Unsinkable spirit and sense of humor
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q35"  class="form-check-input" type="radio" value="b" name="q36">
                                                    <label class="form-check-label">
                                                        Quiet strength and exceptional generosity
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Much of my success has been:
                                            <ul>
                                                <li>
                                                    <input v-model="form.q36"  class="form-check-input" type="radio" value="a" name="q37">
                                                    <label class="form-check-label">
                                                        Due to my talent for making a favorable impression
                                                    </label>
                                                </li>
                                                <li>
                                                    <input v-model="form.q36"  class="form-check-input" type="radio" value="b" name="q37">
                                                    <label class="form-check-label">
                                                        Achieved despite my lack of interest in developing "interpersonal skills"
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <div class="alert alert-danger" v-if="errors.length">
                                        It looks like you missed something. Please make sure you've answered all the questions :D
                                    </div>
                                    <div class="text-center">
                                        <a href="javascript:void(0)" @click.prevent="processResults" class="btn btn-fancy mr-lr-15 fadeInLeft animated" data-animation-in="fadeInLeft" data-animation-in-delay="400ms" tabindex="0" style="animation-delay: 400ms;">Check your score</a>
                                    </div>
                                </div>
                                <div class="col-md-10 mx-auto pos-relative test-form" id="about-page__accordion" v-else>
                                    <h3 class="pb-5" v-if="resultPage">Results for {{ email }}</h3>
                                    <h3 class="pb-5" v-else>Congrats! Here are your results...</h3>
                                    <div class="accordion accordion-default" id="acc-1">
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-11">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-11"  aria-controls="acc-collapse-11">Persister 1 <span class="badge badge-score">{{ result[1] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-11" class="collapse" aria-labelledby="acc-heading-11" data-parent="#acc-1">
                                                <div class="card-body">The principled, idealistic type. Ones are conscientious and ethical, with a strong sense of right and wrong. They are teachers, crusaders, and advocates for change: always striving to improve things, but afraid of making a mistake. Well-organized, orderly, and fastidious, they try to maintain high standards, but can slip into being critical and perfectionistic. They typically have problems with resentment and impatience. At their Best: wise, discerning, realistic, and noble. Can be morally heroic.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-12">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-12"  aria-controls="acc-collapse-12">Harmonizer 1 <span class="badge badge-score">{{ result[2] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-12" class="collapse" aria-labelledby="acc-heading-12" data-parent="#acc-1">
                                                <div class="card-body">The caring, interpersonal type. Twos are empathetic, sincere, and warm-hearted. They are friendly, generous, and self-sacrificing, but can also be sentimental, flattering, and people-pleasing. They are well-meaning and driven to be close to others, but can slip into doing things for others in order to be needed. They typically have problems with possessiveness and with acknowledging their own needs. At their Best: unselfish and altruistic, they have unconditional love for others.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-13">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-13"  aria-controls="acc-collapse-13">Promoter 1 <span class="badge badge-score">{{ result[3] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-13" class="collapse" aria-labelledby="acc-heading-13" data-parent="#acc-1">
                                                <div class="card-body">The adaptable, success-oriented type. Threes are self-assured, attractive, and charming. Ambitious, competent, and energetic, they can also be status-conscious and highly driven for advancement. They are diplomatic and poised, but can also be overly concerned with their image and what others think of them. They typically have problems with workaholism and competitiveness. At their Best: self-accepting, authentic, everything they seem to be—role models who inspire others.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-14">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-14"  aria-controls="acc-collapse-14">Imaginer <span class="badge badge-score">{{ result[4] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-14" class="collapse" aria-labelledby="acc-heading-14" data-parent="#acc-1">
                                                <div class="card-body">The introspective, romantic type. Fours are self-aware, sensitive, and reserved. They are emotionally honest, creative, and personal, but can also be moody and self-conscious. Withholding themselves from others due to feeling vulnerable and defective, they can also feel disdainful and exempt from ordinary ways of living. They typically have problems with melancholy, self-indulgence, and self-pity. At their Best: inspired and highly creative, they are able to renew themselves and transform their experiences.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-15">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-15"  aria-controls="acc-collapse-15">Thinker <span class="badge badge-score">{{ result[5] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-15" class="collapse" aria-labelledby="acc-heading-15" data-parent="#acc-1">
                                                <div class="card-body">The perceptive, cerebral type. Fives are alert, insightful, and curious. They are able to concentrate and focus on developing complex ideas and skills. Independent, innovative, and inventive, they can also become preoccupied with their thoughts and imaginary constructs. They become detached, yet high-strung and intense. They typically have problems with eccentricity, nihilism, and isolation. At their Best: visionary pioneers, often ahead of their time, and able to see the world in an entirely new way.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-16">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-16"  aria-controls="acc-collapse-16">Persister 2 <span class="badge badge-score">{{ result[6] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-16" class="collapse" aria-labelledby="acc-heading-16" data-parent="#acc-1">
                                                <div class="card-body">The committed, security-oriented type. Sixes are reliable, hard-working, responsible, and trustworthy. Excellent "troubleshooters," they foresee problems and foster cooperation, but can also become defensive, evasive, and anxious—running on stress while complaining about it. They can be cautious and indecisive, but also reactive, defiant and rebellious. They typically have problems with self-doubt and suspicion. At their Best: internally stable and self-reliant, courageously championing themselves and others.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-17">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-17"  aria-controls="acc-collapse-17">Rebel <span class="badge badge-score">{{ result[7] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-17" class="collapse" aria-labelledby="acc-heading-17" data-parent="#acc-1">
                                                <div class="card-body">The busy, productive type. Sevens are extroverted, optimistic, versatile, and spontaneous. Playful, high-spirited, and practical, they can also misapply their many talents, becoming overextended, scattered, and undisciplined. They constantly seek new and exciting experiences, but can become distracted and exhausted by staying on the go. They typically have problems with impatience and impulsiveness. At their Best: they focus their talents on worthwhile goals, becoming appreciative, joyous, and satisfied.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-18">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-18"  aria-controls="acc-collapse-18">Promoter 2 <span class="badge badge-score">{{ result[8] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-18" class="collapse" aria-labelledby="acc-heading-18" data-parent="#acc-1">
                                                <div class="card-body">The powerful, aggressive type. Eights are self-confident, strong, and assertive. Protective, resourceful, straight-talking, and decisive, but can also be ego-centric and domineering. Eights feel they must control their environment, especially people, sometimes becoming confrontational and intimidating. Eights typically have problems with their tempers and with allowing themselves to be vulnerable. At their Best: self-mastering, they use their strength to improve others' lives, becoming heroic, magnanimous, and inspiring.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                        <div class="card">
                                            <div class="card-header" id="acc-heading-19">
                                                <h5 class="mb-0"><button class="btn btn-link collapsed d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#acc-collapse-19"  aria-controls="acc-collapse-19">Harmonizer 2 <span class="badge badge-score">{{ result[9] }}</span></button></h5>
                                            </div>
                                            <div id="acc-collapse-19" class="collapse" aria-labelledby="acc-heading-19" data-parent="#acc-1">
                                                <div class="card-body">The easy-going, self-effacing type. Nines are accepting, trusting, and stable. They are usually creative, optimistic, and supportive, but can also be too willing to go along with others to keep the peace. They want everything to go smoothly and be without conflict, but they can also tend to be complacent, simplifying problems and minimizing anything upsetting. They typically have problems with inertia and stubbornness. At their Best: indomitable and all-embracing, they are able to bring people together and heal conflicts.</div>
                                            </div>
                                            <!-- /.collapse -->
                                        </div>
                                        <!-- /.card -->
                                    </div>
                                    <!-- /.accordion-default -->
                                    <highcharts :options="chartOptions"></highcharts>
                                </div>
                            </div>
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
                <!-- /.widget-holder -->
            </div>
            <!-- /.widget-list -->
        </div>
        <!-- /.main-wrappper -->
</template>

<script>
	import axios from 'axios';
	import AOS from 'aos';
	export default {
		name: 'About',
		data () {
			return {
				form: {
					q1: null,
					q2: null,
					q3: null,
					q4: null,
					q5: null,
					q6: null,
					q7: null,
					q8: null,
					q9: null,
					q11: null,
					q12: null,
					q13: null,
					q14: null,
					q15: null,
					q16: null,
					q17: null,
					q18: null,
					q19: null,
					q20: null,
					q21: null,
					q22: null,
					q23: null,
					q24: null,
					q25: null,
					q26: null,
					q27: null,
					q28: null,
					q29: null,
					q30: null,
					q31: null,
					q32: null,
					q33: null,
					q34: null,
					q35: null,
					q36: null,
				},
				result: false,
				chartOptions: {
					credits: {
						enabled: false
					},
					chart: {
						type: 'pie',
						options3d: {
							enabled: true,
							alpha: 45,
							beta: 0
						}
					},
					title: {
						text: ''
					},
					tooltip: {
						pointFormat: '{point.percentage:.1f} %<br>value: {point.y}'
					},
					plotOptions: {
						pie: {
							depth: 35,
							dataLabels: {
								enabled: true
							}
						}
					},
					colors: [
						'#d4a714',
						'#e4b315',
						'#edc43f',
						'#f1d26e',
						'#f7e5ac',
						'#f9eabb',
						'#fdf8ea'
					]
				},
				typeMap: {
					1: 'Persister 1',
					2: 'Harmonizer 1',
					3: 'Promoter 1',
					4: 'Imaginer',
					5: 'Thinker',
					6: 'Perister 2',
					7: 'Rebel',
					8: 'Promoter 2',
					9: 'Harmonizer 2',
				},
				errors: []
			};
		},
		computed: {
			email () {
				return this.resultPage ? atob(this.$route.params.email) : this.$route.params.email;
			},
			results () {
				return this.resultPage ? JSON.parse(atob(this.$route.params.results)) : false;
			},
			resultPage () {
				return typeof this.$route.params.results !== "undefined";
			}
		},
		methods: {
			async processResults () {
				this.errors = [];
				for (let key in this.form) {
					if (!this.form[key]) {
						this.errors.push(key);
					}
				}
				if (!this.errors.length) {
					this.result = await axios.post('/.netlify/functions/process-my-personality?email=' + this.email, this.form).then(res => res.data.result);
					this.renderChart();
					this.$scrollTo('#about-page__accordion', 200, {
						easing: 'ease-in',
						offset: -100
					});
				}
			},
			renderChart () {
				const data = [];
				for (let key in this.result) {
					data.push ({
						name: this.typeMap[key],
						y: this.result[key]
					});
				}
				this.chartOptions.series = [
					{
						name: 'Type',
						data
					}
				];
			}
		},
		mounted () {
			AOS.refresh();
			if (this.resultPage) {
				this.result = this.results;
				this.renderChart();
			}
		}
	}
</script>
<style lang="scss">
    .test-form ul li {
        list-style: none;
    }

    .bam {
        color: #444;
    }
    button {    
        .badge-score {
            background-color: var(--primary);
            color: #fff;
            font-size: 16px;
            font-weight: 300;
        }

        &[aria-expanded="true"] {
            .badge-score {
                color: var(--primary); background: #fff;
            }
        } 
    }
</style>
