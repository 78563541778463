import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCalendly from 'vue-calendly';
import * as VueScrollTo from 'vue-scrollto';

import Highcharts from "highcharts";
import Highcharts3d from "highcharts/highcharts-3d";
import HighchartsVue from "highcharts-vue";

Highcharts3d(Highcharts);
Vue.use(HighchartsVue);


Vue.use(VueRouter)
Vue.use(VueCalendly);
Vue.use(VueScrollTo);

import App from './App.vue'
import Home from './views/Home.vue'
import Course from './views/Course.vue'
import About from './views/About.vue'
import Test from './views/Test.vue'
import Share from './views/Share.vue'
import MyMindEvolved from './views/MyMindEvolved.vue'

Vue.config.productionTip = false
const hideHeader = true;
const routes = [
  { path: '/', component: Home },
  { path: '/course/:action?', component: Course },
  { path: '/about-me', component: About },
  { path: '/discovering-you/:email', component: Test },
  { path: '/discovering-you-results/:email/:results', component: Test },
  { path: '/my-mind-evolved/:name', component: MyMindEvolved },
  { path: '/share/:resource?', component: Share, meta: { hideHeader } }
];

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes // short for `routes: routes`
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
