<template>
        <div class="container-fluid main-wrapper clearfix">
            <!-- =================================== -->
            <!-- Different data widgets ============ -->
            <!-- =================================== -->
            <div class="widget-list row">
                <div id="top"></div>
                <div id="group-session-signup" class="widget-holder col-md-12 p-0" style="">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                              <div class="col-md-12 text-center pd-b-50">
                                <div v-if="action === 'success'" class="alert alert-success">Thanks for signing up! We'll be in touch soon to get you onboarded :D</div>
                                <div v-if="action === 'cancel'" class="alert alert-warning">It looks like you might have had an issue checking out... Please feel free to try again or if you need help <button class="btn btn-info btn-sm contact-me">Contact Us</button></div>
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" data-aos="fade-up" data-aos-duration="800">
                                    <div class="tab-content" id="myTabContent">
                                      <div class="tab-pane fade text-center show active" id="private" role="tabpanel" aria-labelledby="home-tab">
                                        <h2 class="fancy-heading">Welcome, {{ name }}!</h2>
                                        <video controls style="width: 100%" poster="https://storage.googleapis.com/cherise/poster.png">
                                          <source src="https://storage.googleapis.com/cherise/onboarding.mp4" type="video/mp4">
                                          Your browser does not support HTML video.
                                        </video>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
            </div>
            <!-- /.widget-list -->
            <div id="loom-modal" class="modal animated fadeInDown" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                    </button> 
                    <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/f8b5425005534446a0b677e9e2073276" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
                  </div>
                </div>
              </div>
            </div>
      </div>
</template>

<script>
import AOS from 'aos';
//import { StripeCheckout } from 'vue-stripe-checkout';
export default {
  name: 'MyMindEvoled',
  components: {
   // StripeCheckout
  },
  data () {
    return {
      loading: false,
      publishableKey: 'pk_live_rIGlZkA2GerkkSlgokChv68P',
      items: {
        bronze: {
            monthly: [{plan: 'plan_HHYA4k5dTd1sf0', quantity: 1}],
            annual: [{plan: 'plan_HHYAF318RTKWJM', quantity: 1}]
        },
        silver: {
            monthly: [{plan: 'plan_HHYAYuOcffFnkd', quantity: 1}],
            annual: [{plan: 'plan_HHYAE691deLrru', quantity: 1}]
        },
        gold: {
            monthly: [{plan: 'plan_HHYA4E8IlMqvEw', quantity: 1}],
            annual: [{plan: 'plan_HHYAcvkCO3yGn0', quantity: 1}]
        },
        vip: {
            monthly: [{plan: 'plan_HHYAyqslzRPwsb', quantity: 1}],
            annual: [{plan: 'plan_HHYAMhnzErDmTE', quantity: 1}]
        }
      },
      successUrl: 'http://yourstressremedy.com/#/my-mind-evolved/success',
      cancelUrl: 'http://yourstressremedy.com/#/my-mind-evolved/cancel',
      annualPctOff: 12
    }
  },
  methods: {
    checkout (refKey) {
      this.$refs[refKey].redirectToCheckout();
    }
  },
  computed: {
    action () {
      return this.$route.params.action;
    },
    name () {
      return this.$route.params.name;
    }
  },
  mounted () {
    AOS.refresh();
    document.dispatchEvent(new Event('DOMContentLoaded'));
  }
}
</script>

<style lang="scss">
  /*#carousel-quotes-1-nav {
    .slick-track {
      width: auto !important;
    }
  }*/
  #navbar {
    background: #000;
    &.js-is-sticky {
      background: #fff;
    }
  }

  #group-session-signup {
    margin-top: 100px;
  }

  .nav-item {

    a {
        padding: 0px 10px;
    }
  }
</style>
