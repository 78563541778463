<template>
  <div id="wrapper" class="wrapper">
        <div class="navbar header-navbar sticky-wrapper" :class="$route.meta.hideHeader ? 'd-none':''" id="navbar" role="banner" itemscope="itemscope" itemtype="https://schema.org/WPHeader">
            <!-- Logo Area -->
            <div class="navbar-header">
                <router-link to="/" class="navbar-brand">
                    <span class="light-logo"><img src="/assets/img/logo.png" style="height: 40px;" /></span>
                    <span class="sticky-logo"><img src="/assets/img/logo.png" style="height: 40px;" /></span>
                    <span class="dark-logo"><img src="/assets/img/logo.png" style="height: 40px;" /></span>
                    <!--<img src="assets/img/logo-2.png" alt="" class="light-logo" width="200" height="80">
                    <img src="assets/img/logo-light.png" alt="" class="dark-logo" width="200" height="80">
                    <img src="assets/img/logo-light.png" alt="" class="sticky-logo" width="200" height="80">-->
                    <!-- <p>Vibes</p> -->
                </router-link>
            </div>
            <!-- /.navbar-header -->
            <!-- Main Navigation -->
            <nav class="nav-container d-none d-lg-block" aria-hidden="true" itemscope="itemscope" itemtype="https://schema.org/SiteNavigationElement">
                <ul class="nav site-navigation" id="navbar-menu-1">
                    <li class="menu-item current-menu-item"><router-link to="/">Home</router-link></li>
                    <li class="menu-item"><router-link to="/about-me">About Me</router-link></li>
                </ul>
            </nav>
            <!-- /.nav-container -->
            <!-- Main Navigation for tablet and mobiles -->
            <nav class="nav-container d-block d-lg-none" aria-hidden="true" itemscope="itemscope" itemtype="https://schema.org/SiteNavigationElement">
                <ul class="nav site-navigation" id="navbar-menu-2">
                    <li class="menu-item menu-right-sidebar"><a href="javascript: void(0);" id="sidebar-toggle-2" class="sidebar-toggle" data-template-sidebar-trigger="#right-sidebar-2" data-template-open="false"><i class="feather-menu"></i></a>
                        <div id="right-sidebar-2" class="sidebar right-sidebar"
                        data-template-open="false">
                            <div class="right-sidebar-body">
                                <div class="clearfix mb-0">
                                    <button class="close"><i class="feather-x"></i>
                                    </button>
                                </div>
                                <ul class="menu">
                                    <li class="menu-item close-menu"><router-link to="/">Home</router-link></li>
                                    <li class="menu-item close-menu"><router-link to="/about-me">About Me</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
    </div>
    <!-- /.navbar -->
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
    <!-- /.content-wrapper -->
    <div class="widget-holder col-md-12 p-0" id="main-footer">
        <div class="widget-bg text-white">
            <div class="widget-body container-fluid">
                <div class="row">
                    <div class="col-md-10 mx-auto text-center">
                        <!--<blockquote class="footer-quote">"Do the things that make for peace in the moment; seek truth and pursue it."</blockquote>-->
                        <blockquote class="footer-quote">"Until you make the unconscious conscious, it will direct your life and you will call it fate." &ndash; Carl Jung</blockquote>
                        <!-- Footer Navigation -->
                        <!--<nav class="nav-container mr-b-20">
                            <ul class="generic-nav">
                                <li class="menu-item current-menu-item"><router-link to="/">Home</router-link></li>
                                <li class="menu-item"><router-link to="/process">About The Process</router-link></li>
                            </ul>
                        </nav>-->
                        <!-- /.nav-container -->
                        <!-- Social Links -->
                        <!--<ul class="social-list social-list-colored-circle">
                            <li><a href="twitter.com" class="bg-twitter"><i class="unicon-twitter"></i></a>
                            </li>
                            <li><a href="facebook.com" class="bg-facebook"><i class="unicon-facebook"></i></a>
                            </li>
                            <li><a href="instagram.com" class="bg-instagram"><i class="unicon-instagram"></i></a>
                            </li>
                            <li><a href="youtube.com" class="bg-youtube"><i class="unicon-youtube"></i></a>
                            </li>
                        </ul>-->
                        <!-- /.social-list -->
                    </div>
                    <!-- /.col-md-12 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.widget-body -->
        </div>
        <!-- /.widget-bg -->
    </div>
    <!-- /.widget-holder -->
    <div class="widget-holder col-md-12 p-0" id="main-subfooter">
        <div class="widget-bg text-white">
            <div class="widget-body container-fluid">
                <div class="row">
                    <div class="col-md-10 mx-auto text-center">
                        <div class="text-center">
                            <p class="fs-12 text-muted">mymindevolved.com &copy; 2020<br />Made with ♥</p>
                        </div>
                    </div>
                    <!-- /.col-md-12 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.widget-body -->
        </div>
        <!-- /.widget-bg -->
    </div>
    <!-- /.widget-holder -->
  </div>
</template>

<script>
import AOS from 'aos';
export default {
  name: 'app',
  created () {
    AOS.init();
  }
}
</script>

<style lang="scss">
  #navbar {
    background: #fff !important;
    a {
      color: #000 !important;
    }
  }
  .navbar-brand {
    font-family: 'Open Sans Condensed', sans-serif;
    line-height: 80px;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 20px;
    .light-logo {
      color: #fff;
    }
    .dark-logo {
      color: #000;
    }
    .sticky-logo {
      line-height: 60px;
      color: #000;
      @media (max-width: 576px) {
        line-height: 48px !important;
        font-size: 15px !important;
      }
    }

    b {
      font-weight: 700;
    }

    @media (max-width: 576px) {
      line-height: 48px !important;
      font-size: 15px !important;
    }
  } 

  #newsletter-modal__side-col {
    padding-top: 325px;
    background-image: url('./assets/hannah-face.png');
    background-color: #000;
    background-size: 250px;
    background-position: center 70px;
  }

  .modal-dialog {
    max-width: 700px !important;
  }

  .footer-quote {
    background: #251e28;
  }
</style>
