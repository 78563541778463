<template>
        <div class="container-fluid main-wrapper clearfix">
            <!-- =================================== -->
            <!-- Different data widgets ============ -->
            <!-- =================================== -->
            <div class="widget-list row">
                <div id="top"></div>
                <div id="group-session-signup" class="widget-holder col-md-12 p-0" style="">
                    <div class="widget-bg">
                        <div class="widget-body container">
                            <div class="row">
                              <div class="col-md-12 text-center pd-b-50">
                                <div v-if="action === 'success'" class="alert alert-success">Thanks for signing up! We'll be in touch soon :D</div>
                                <div v-if="action === 'cancel'" class="alert alert-warning">It looks like you might have had an issue checking out... Please feel free to try again or if you need help <button class="btn btn-info btn-sm contact-me">Contact Us</button></div>
                                <h1 data-aos="fade-right">Your Stress Remedy Course</h1>
                                <div v-if="action === '' || action === 'cancel'">
                                <p class="d-none d-lg-block" data-animation-in="fadeInRight" data-animation-in-delay="1ms" style="font-size: 25px;">
                                Identify your distress signals<br />& create an action plan for your needs<br />
                                </p>
                                <p style="color: #000; font-weight: 300; font-size: 15px; margin: 50px 0px 50px 0px;">
                                    This program is designed for you to complete at your own pace.<br /> 
The goal is to increase your level of awareness on how you process stress so that you easily identify your triggers or better management.
                                </p>
                                <div class="row" id="icon-box-list">
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-duration="800">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-lotus-1 text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Learn the difference in Stress and Anxiety</h6>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-delay="100" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-lotus-1 text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Identify Stressors in 4 areas of Life: Health, Thoughts & Core Beliefs, Relationships, Productivity</h6>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-lotus-1 text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Gain Applicable tools that you can use to track and manage Stress & Anxiety.</h6>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <figure><i class="vibes-lotus-1 text-tertiary"></i>
                                                </figure>
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Learn grounding techniques to soothe anxiety and ease stress on your own.</h6>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                        <!-- /.col-md-6 -->
                                        <div class="col-md-6 offset-md-3" data-aos="zoom-out" data-aos-delay="300" data-aos-duration="800" data-aos-anchor="#icon-box-list>div:first-child">
                                            <div class="media icon-box icon-box-default">
                                                <div class="media-body">
                                                    <h6 class="icon-box-title">Bonus Training on Time Management and Self Care Routines</h6>
                                                </div>
                                            </div>
                                            <!-- /.icon-box -->
                                        </div>
                                    </div>
                                    <!-- /.row -->

                                <stripe-checkout v-if="stripe"
                                ref="course"
                                :pk="publishableKey"
                                :items="items.course"
                                :successUrl="successUrl"
                                :cancelUrl="cancelUrl">
                                <template slot="checkout-button">
                                  <a href="javascript:void(0)" class="btn btn-fancy" style="margin: 50px 0px 0px 0px" data-animation-in="fadeInLeft" data-animation-in-delay="400ms" @click.prevent="checkout('course')">Enroll Now <i class="feather-arrow-right fs-18 ml-3 align-middle"></i> </a>
                                </template>
                              </stripe-checkout>
                                </div>
                                <div v-else>
                                <h4>Schedule your 15 min Clarity Session to get started:</h4>
                                <vue-calendly url="https://calendly.com/hannah-bacaron/15-clarity-call?hide_event_type_details=1" :height="720"></vue-calendly>
                                </div>
                                <p class="mr-t-40 text-center">Still have questions?<br />
                                <button class="btn btn-sm btn-info contact-me"><i class="feather-mail"></i><span>Contact Me</span></button></p>
                                <!--<img src="assets/demo/home/signature.png" alt="Signature">-->
                            </div>
                            <!-- /.row -->
                        </div>
                        <!-- /.widget-body -->
                    </div>
                    <!-- /.widget-bg -->
                </div>
                <!-- /.widget-holder -->
            </div>
            </div>
            <!-- /.widget-list -->
            <div id="loom-modal" class="modal animated fadeInDown" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                    </button> 
                    <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/f8b5425005534446a0b677e9e2073276" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
                  </div>
                </div>
              </div>
            </div>
      </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import { StripeCheckout } from 'vue-stripe-checkout';
import AOS from 'aos';
export default {
  name: 'Course',
  data () {
    return {
      publishableKey: 'pk_live_rIGlZkA2GerkkSlgokChv68P',
      //publishableKey: 'pk_test_nyym3lnqgVqWLSsJLyltMFl6',
      items: {
        course: [{price: 'price_HM0WrP8sfGAcqD', quantity: 1}]
        //course: [{price: 'price_HM1i9AmDlbdZFN', quantity: 1}]
      },
      mode: 'payment',
      successUrl: 'https://yourstressremedy.com/#/course/success',
      cancelUrl: 'https://yourstressremedy.com/#/course/cancel',
      stripe: false
    }
  },
  components: {
    StripeCheckout
  },
  methods: {
    checkout (refKey) {
      this.stripe.redirectToCheckout({
      lineItems: this.items[refKey],
      mode: 'payment',
      successUrl: this.successUrl,
      cancelUrl: this.cancelUrl
      })
    }
  },
  computed: {
    action () {
      return this.$route.params.action || '';
    }
  },
  mounted () {
    AOS.refresh();
    document.dispatchEvent(new Event('DOMContentLoaded'));
    loadStripe(this.publishableKey).then(stripe => {
      this.stripe = stripe;
    });
  }
}
</script>

<style lang="scss">
  /*#carousel-quotes-1-nav {
    .slick-track {
      width: auto !important;
    }
  }*/
  #navbar {
    background: #000;
    &.js-is-sticky {
      background: #fff;
    }
  }

  #group-session-signup {
    margin-top: 100px;
  }

  .nav-item {

    a {
        padding: 0px 10px;
    }
  }
</style>
